<template>
  <base-view title="Formas de pago" icon="dollar-sign">
    <div>
      <check-authorization :requiresAuthorizations="['indice formas de pago']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Formas de pago

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear formas de pago']"
                :overrideIf="$store.getters['formaDePagoModule/isEditingResource']"
              >
                <forma-de-pago-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <forma-de-pago-list @on-got-item="showForm = true"></forma-de-pago-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import FormaDePagoForm from '@/components/Catalogos/FormasDePago/FormaDePagoForm'
import FormaDePagoList from '@/components/Catalogos/FormasDePago/FormaDePagoList'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'formaDePagoModule'

export default {
  name: 'FormasDePagoView',

  components: {
    FormaDePagoForm,
    FormaDePagoList
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
